
.currentPage {
    text-decoration: underline;
    font-family: 'Quicksand', sans-serif;
  }

.BlogPreview {
  padding-bottom: 10em;
}

.BlogPost {
  margin: 5em;
}

.previewText {
  font-size: 150%;
}

main-content {
  margin: 1em;
}

img-responsive {
  margin: 1em;
  
}

p {
  margin: 0 0 2.5em 2.5em;
}

.wrapper 
{
  max-width: 800px;
  word-wrap: break-word;
  margin: 0 auto;
}